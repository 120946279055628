import { render, staticRenderFns } from "./DevSandboxIndex.vue?vue&type=template&id=6d368775&scoped=true"
import script from "./DevSandboxIndex.vue?vue&type=script&lang=js"
export * from "./DevSandboxIndex.vue?vue&type=script&lang=js"
import style0 from "./DevSandboxIndex.vue?vue&type=style&index=0&id=6d368775&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d368775",
  null
  
)

export default component.exports